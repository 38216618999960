.container {
    display: inline-flex;

    img {
        margin-right: 6px;
    }

    .bold{
        font-weight: 600;
    }

    a {
        text-decoration: none;
    }
}