@import "../../constants.scss";

.container {
    display: grid;
    grid-template-columns: 24px auto auto 24px;
    grid-template-rows: 24px auto 24px;
    background-color: #171E27;
    height: 100vh;
    grid-column-gap: 24px;
    gap: 24px;
    justify-content: inherit;

    .profilePanel {
        grid-column-start: 2;
        grid-row-start: 2;
    }

    > .content {
        grid-column-start: 3;
        grid-row-start: 2;
    }

    .content {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 260px auto auto;
        column-gap: 24px;
        row-gap: 24px;

        > :first-child {
            grid-column-start: 1;
            grid-row-start: 1;
        }

        > :nth-child(2) {
            grid-column-start: 2;
            grid-row-start: 1;
        }

        > :nth-child(3) {
            grid-column-start: 3;
            grid-row-start: 1;
        }

        > :nth-child(4) {
            grid-column-start: 4;
            grid-row-start: 1;
        }

        > :nth-child(5) {
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 2;
        }

        > :nth-child(6) {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 3;
        }

        > :nth-child(7) {
            grid-column-start: 4;
            grid-row-start: 3;
        }
    }
}