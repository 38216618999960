@import "../../constants.scss";

.label {
    font-size: 14px;
    font-weight: 100;
    opacity: 0.65;
}

.value {
    font-size: 24px;
    font-weight: bold;
}
