.container {
    display: flex;
    flex-direction: column;
    width: 300px;

    > * {
        margin-bottom: 12px;
    }

    h2 {
        margin: 0;
        font-weight: 500;
    }

    .profileName {
        opacity: 0.6;
    }

    .location{
        display: flex;
        flex-direction: column;
    }

    .emails {
        display: flex;
        flex-direction: column;
    }

    .bio {
        white-space: pre-line;
    }
}