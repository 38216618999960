@import "../../constants.scss";

.container {
    position: relative;

    .sendButton {
        display: flex;
        height: 60px;
        width: 60px;
        background-color: #0E294B;
        border-radius: 36px;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 36px;
        right: 48px;
    }

    .image {
        width: 254px;
        height: 254px;
        border-radius: 254px;
    }
}

