@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap);
.HomePage_container__v_lgM{display:grid;grid-template-columns:24px auto auto 24px;grid-template-rows:24px auto 24px;background-color:#171e27;height:100vh;grid-column-gap:24px;grid-gap:24px;gap:24px;-webkit-justify-content:inherit;justify-content:inherit}.HomePage_container__v_lgM .HomePage_profilePanel__YLFsM{grid-column-start:2;grid-row-start:2}.HomePage_container__v_lgM>.HomePage_content__2RotN{grid-column-start:3;grid-row-start:2}.HomePage_container__v_lgM .HomePage_content__2RotN{display:grid;grid-template-columns:auto auto auto auto;grid-template-rows:260px auto auto;grid-column-gap:24px;-webkit-column-gap:24px;column-gap:24px;grid-row-gap:24px;row-gap:24px}.HomePage_container__v_lgM .HomePage_content__2RotN>:first-child{grid-column-start:1;grid-row-start:1}.HomePage_container__v_lgM .HomePage_content__2RotN>:nth-child(2){grid-column-start:2;grid-row-start:1}.HomePage_container__v_lgM .HomePage_content__2RotN>:nth-child(3){grid-column-start:3;grid-row-start:1}.HomePage_container__v_lgM .HomePage_content__2RotN>:nth-child(4){grid-column-start:4;grid-row-start:1}.HomePage_container__v_lgM .HomePage_content__2RotN>:nth-child(5){grid-column-start:1;grid-column-end:5;grid-row-start:2}.HomePage_container__v_lgM .HomePage_content__2RotN>:nth-child(6){grid-column-start:1;grid-column-end:4;grid-row-start:3}.HomePage_container__v_lgM .HomePage_content__2RotN>:nth-child(7){grid-column-start:4;grid-row-start:3}
.ProfilePanel_container__3ubbs{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;width:300px}.ProfilePanel_container__3ubbs>*{margin-bottom:12px}.ProfilePanel_container__3ubbs h2{margin:0;font-weight:500}.ProfilePanel_container__3ubbs .ProfilePanel_profileName__EG3Qf{opacity:.6}.ProfilePanel_container__3ubbs .ProfilePanel_location__1n3Bk{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column}.ProfilePanel_container__3ubbs .ProfilePanel_emails__m6MHl{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column}.ProfilePanel_container__3ubbs .ProfilePanel_bio__3eXlL{white-space:pre-line}
.ProfileImage_container__pRe_B{position:relative}.ProfileImage_container__pRe_B .ProfileImage_sendButton__3e0CC{display:-webkit-flex;display:flex;height:60px;width:60px;background-color:#0e294b;border-radius:36px;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;position:absolute;bottom:36px;right:48px}.ProfileImage_container__pRe_B .ProfileImage_image__pcQx-{width:254px;height:254px;border-radius:254px}
.FollowPanel_container__2nxrn{padding:0}.FollowPanel_container__2nxrn li{display:inline;padding-right:12px}
.Text_container__39Ld6{display:-webkit-inline-flex;display:inline-flex}.Text_container__39Ld6 img{margin-right:6px}.Text_container__39Ld6 .Text_bold__1Vp7F{font-weight:600}.Text_container__39Ld6 a{text-decoration:none}
.SearchBar_container__3lWxu{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;background-color:#12171f;border-radius:12px;padding:16px}
.card_container__VYbdS{background-color:#12171f;padding:24px;border-radius:12px}.card_container__VYbdS .card_headerWrapper__2Rzza{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center}.card_container__VYbdS .card_headerWrapper__2Rzza .card_header__2jpJW{color:#c9d1d9;font-weight:600}.card_container__VYbdS .card_headerWrapper__2Rzza .card_subheader__1pSyC{color:#e1e3e6}.card_container__VYbdS .card_content__3fV0v{margin-top:24px}
.OverviewCard_container__1cXAy{display:grid;grid-template-columns:auto auto;grid-template-rows:auto 12px auto}.OverviewCard_container__1cXAy :first-child{grid-column-start:1;grid-row-start:1;justify-self:start}.OverviewCard_container__1cXAy :nth-child(2){grid-column-start:2;grid-row-start:1;justify-self:start}.OverviewCard_container__1cXAy :nth-child(3){grid-column-start:1;grid-row-start:3}.OverviewCard_container__1cXAy :nth-child(4){grid-column-start:2;grid-row-start:3}
.Text_label__FKWkp{font-size:14px;font-weight:100;opacity:.65}.Text_value__32x6y{font-size:24px;font-weight:bold}
.Loader_loader__2hZcM{border:8px solid #00000000;border-radius:50%;border-top:8px solid #355aa7;border-bottom:8px solid #355aa7;width:90px;height:90px;-webkit-animation:Loader_spin__1X-nh 2s linear infinite;animation:Loader_spin__1X-nh 2s linear infinite}@-webkit-keyframes Loader_spin__1X-nh{0%{-webkit-transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg)}}@keyframes Loader_spin__1X-nh{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}
.FullscreenWrapper_container__3ibFg{background:#00000073;position:absolute;top:0px;left:0px;z-index:10;height:100%;width:100%;-webkit-align-items:center;align-items:center;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center}
.BarGraph_bar__1Z805{fill:#355aa7}
body{font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;font-size:16px;color:#ededed;margin:0}body a{color:#ededed;text-decoration:none;cursor:pointer}
