@import "../../constants.scss";

.loader {
    border: 8px solid #00000000;
    border-radius: 50%;
    border-top: 8px solid $secondary-color;
    border-bottom: 8px solid $secondary-color;
    width: 90px;
    height: 90px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
  }
