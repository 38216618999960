@import "../../constants.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background-primary-color;
  border-radius: 12px;
  padding: 16px;
}
