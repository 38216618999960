@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap');
@import "./constants.scss";

body {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    font-size: 16px;
    color: $text-primary-color;
    margin: 0;

    a {
        color: $text-primary-color;
        text-decoration: none;
        cursor: pointer;
    }
}