@import "../../constants.scss";

.container {
    background-color: $background-primary-color;
    padding: 24px;
    border-radius: 12px;
    // min-width: 240px;

    .headerWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        .header {
            color: $header-primary-color;
            font-weight: 600;
        }
    
        .subheader {
            color: $text-secondary-color;
        }
    }


    .content {
        margin-top: 24px;
    }
}